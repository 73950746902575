import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../components/Text/PageTitle';

const ListHeader = styled('li')(() => ({
  paddingTop: '1rem',
  fontWeight: 'bold',
}));

const ListItem = styled('li')(() => ({
  paddingTop: '1rem',
}));

const termsAndConditionsStructure = [
  {
    key: 'useOfServices',
    children: [
      { key: '1' },
      { key: '2' },
      { key: '3' },
      { key: '4', children: [{ key: '1' }, { key: '2' }, { key: '3' }, { key: '4' }] },
      { key: '5' },
      { key: '6' },
      { key: '7' },
    ],
  },
  {
    key: 'obligations',
    children: [
      { key: '1' },
      { key: '2', children: [{ key: '1' }, { key: '2' }, { key: '3' }, { key: '4' }] },
      { key: '3' },
    ],
  },
  {
    key: 'accessInformation',
    children: [
      { key: '1' },
      { key: '2' },
      { key: '3', children: [{ key: '1' }, { key: '2' }, { key: '3' }] },
      { key: '4' },
      { key: '5' },
    ],
  },
  {
    key: 'proprietaryRights',
    children: [{ key: '1' }, { key: '2' }, { key: '3' }],
  },
  {
    key: 'equipmentAndTechnologyCompatibility',
    children: [{ key: '1' }],
  },
  {
    key: 'liabilityForContent',
    children: [{ key: '1' }, { key: '2' }],
  },
  {
    key: 'liabilityForThirdPartyContent',
    children: [{ key: '1' }],
  },
  {
    key: 'exclusionOfLiability',
    children: [
      { key: '1' },
      { key: '2', children: [{ key: '1' }, { key: '2' }, { key: '3' }] },
      { key: '3' },
      { key: '4' },
    ],
  },
  {
    key: 'variation',
    children: [{ key: '1' }, { key: '2' }],
  },
  {
    key: 'auditLoggingAndMonitoring',
    children: [{ key: '1' }],
  },
  {
    key: 'termination',
    children: [{ key: '1' }, { key: '2' }],
  },
  {
    key: 'yourInformation',
    children: [{ key: '1' }],
  },
  {
    key: 'general',
    children: [{ key: '1' }, { key: '2' }, { key: '3' }, { key: '4' }, { key: '5' }, { key: '6' }, { key: '7' }],
  },
  {
    key: 'definitions',
    children: [
      { key: '1' },
      { key: '2' },
      { key: '3' },
      { key: '4' },
      { key: '5' },
      { key: '6' },
      { key: '7' },
      { key: '8' },
      { key: '9' },
      { key: '10' },
      { key: '11' },
      { key: '12' },
      { key: '13' },
      { key: '14' },
      { key: '15' },
    ],
  },
];

function TermsAndConditions() {
  const [t] = useTranslation();
  return (
    <Box>
      <PageTitle>{t('translation:pages.termsAndConditions.title')}</PageTitle>
      <Grid container sx={{ paddingTop: '1.5rem', paddingBottom: '2.5rem' }}>
        <Grid item xl={12} md={12} xs={12}>
          <Typography>{t('translation:pages.termsAndConditions.message')}</Typography>
          <ol
            style={{
              textIndent: '1rem',
            }}
          >
            {termsAndConditionsStructure.map((element) => (
              <>
                <ListHeader>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    {t(`translation:pages.termsAndConditions.${element.key}.title`)}
                  </Typography>
                </ListHeader>
                {element.children && (
                  <ol>
                    {element.children.map((child) => (
                      <ListItem key={`${element.key}_${child.key}`}>
                        <Typography>{t(`translation:pages.termsAndConditions.${element.key}.${child.key}`)}</Typography>
                        {child.children && (
                          <ol>
                            {child.children.map((nestedChild) => (
                              <ListItem key={`${element.key}_${child.key}-${nestedChild.key}`}>
                                <Typography>
                                  {t(
                                    `translation:pages.termsAndConditions.${element.key}.${child.key}-${nestedChild.key}`,
                                  )}
                                </Typography>
                              </ListItem>
                            ))}
                          </ol>
                        )}
                      </ListItem>
                    ))}
                  </ol>
                )}
              </>
            ))}
          </ol>
        </Grid>
      </Grid>
    </Box>
  );
}

export default TermsAndConditions;
